const axios = require("axios");
const _merge = require("lodash.merge");

const GATSBY_YOTPO_APP_KEY = process.env.GATSBY_YOTPO_APP_KEY;
const GATSBY_YOTPO_DOMAIN = process.env.GATSBY_YOTPO_DOMAIN;
const GATSBY_YOTPO_SECRET_KEY = process.env.GATSBY_YOTPO_SECRET_KEY;

async function createReview (
  {
    product_id,
    product_title,
    product_url,
    product_image_url,
    display_name,
    email,
    review_content,
    review_title,
    review_score
  }
) {
  const data = {
    appkey: GATSBY_YOTPO_APP_KEY,
    domain: GATSBY_YOTPO_DOMAIN,
    sku: `${product_id}`,
    product_title,
    product_url,
    product_image_url,
    display_name,
    email,
    review_content,
    review_title,
    review_score
  };
  await axios.post("https://api.yotpo.com/v1/widget/reviews", data);
}

async function getAllReviews () {
  const auUrl = 'https://api.yotpo.com/oauth/token';
  const auResponse = await axios.post(auUrl, {"client_id": GATSBY_YOTPO_APP_KEY,"client_secret": GATSBY_YOTPO_SECRET_KEY,"grant_type": "client_credentials"});
  const uToken = auResponse.data.access_token;
  const url = `https://api.yotpo.com/v1/apps/${GATSBY_YOTPO_APP_KEY}/reviews?utoken=${uToken}&page=1&count=50`;
  const response = await axios.get(url);
  return response.data.reviews;
}

async function getAllSiteReviews (products) {
  const idArray = products.flatMap(function (product){
    const childProductIdArray = product.node.grouped_products_nodes.flatMap(function (node){
      return node.wordpress_id;
    })
    return childProductIdArray.concat(product.node.wordpress_id);
  })
  const url = `https://api.yotpo.com/v1/apps/${GATSBY_YOTPO_APP_KEY}/bottom_lines?count=50&page=1`;
  const response = await axios.get(url);
  const allProductReview = response.data.response.bottomlines.filter(function(bottomline){
    return typeof idArray.find(function(id){
       return id.toString() === bottomline.domain_key
    }) !== "undefined";
  });
  var reviewCount = 0;
  var reviewScore = 0;
  allProductReview.forEach(function(review){
    reviewCount = reviewCount + review.total_reviews;
    reviewScore = reviewScore + review.product_score * review.total_reviews
  })
  return {'avg_rating':reviewScore/reviewCount,'total_reviews':reviewCount}
}

async function getAllProductsBottomLine () {
  const url = `https://api.yotpo.com/v1/apps/${GATSBY_YOTPO_APP_KEY}/bottom_lines?count=80&page=1`;
  const response = await axios.get(url);
  return response.data.response.bottomlines;
}

async function getProductReviews (product_id, params = {}) {
  params = _merge({
    // defaults
    per_page: 150,
    page: 1,
    star: null, // Star rating for the product (1 to 5)
    sort: "date", // date, votes_up, votes_down, time, rating, or reviewer_type
    direction: "desc" // desc or asc
  }, params);

  if (!params.star) delete params.star;

  const url = `https://api.yotpo.com/v1/widget/${GATSBY_YOTPO_APP_KEY}/products/${product_id}/reviews.json`;
  const response = await axios.get(url, { params });
  return response.data;
}

async function getGroupedProductReviews (product) {
  const wordpress_id = product.wordpress_id;
  const product_type = product.type;
  //console.log(`fetch reviews for product (wordpress_id=${wordpress_id}, product_type=${product_type})`);
  let parentReviews = (await getProductReviews(wordpress_id)).response;

  // request reviews for child products of "grouped" product

  if (product_type === "grouped") {
    const childReviews = [];
    for (let j = 0; j < product.grouped_products_nodes.length; j++) {
      const childProduct = product.grouped_products_nodes[j];

      const wordpress_id = childProduct.wordpress_id;
      const product_type = childProduct.type;
      //console.log(`fetch reviews for product (wordpress_id=${wordpress_id}, product_type=${product_type})`);
      const childReviewsData = (await getProductReviews(wordpress_id)).response;
      childReviews.push(childReviewsData);
    }
    parentReviews = mergeReviews(parentReviews, childReviews);
  }
  //console.log("total_reviews:",parentReviews.bottomline.total_review);
  return parentReviews;
}

/**
 * Need merge, cause on product page, have two child products: simple and subscription.
 * So we can not fetch reviews for 2 products in one request.
 * Fetch reviews for each of both product separately and merge, reviews and statistics
 *
 * @param parentReviews
 * @param childReviewsGroups
 * @return {*}
 */
function mergeReviews (parentReviews, childReviewsGroups) {
  if (childReviewsGroups.length) {
    // merge reviews
    childReviewsGroups.forEach(chGroup => chGroup.reviews.forEach(review => parentReviews.reviews.push(review)));

    // merge bottom lines
    const total_review = parentReviews.bottomline.total_review + childReviewsGroups.reduce((sum, g) => sum + g.bottomline.total_review, 0);
    const star_distribution = {
      "5": (parentReviews.bottomline.star_distribution != null ? parentReviews.bottomline.star_distribution[5] : 0) + childReviewsGroups.reduce((sum, g) => sum + (g.bottomline.star_distribution != null ? g.bottomline.star_distribution[5] : 0), 0),
      "4": (parentReviews.bottomline.star_distribution != null ? parentReviews.bottomline.star_distribution[4] : 0) + childReviewsGroups.reduce((sum, g) => sum + (g.bottomline.star_distribution != null ? g.bottomline.star_distribution[4] : 0), 0),
      "3": (parentReviews.bottomline.star_distribution != null ? parentReviews.bottomline.star_distribution[3] : 0) + childReviewsGroups.reduce((sum, g) => sum + (g.bottomline.star_distribution != null ? g.bottomline.star_distribution[3] : 0), 0),
      "2": (parentReviews.bottomline.star_distribution != null ? parentReviews.bottomline.star_distribution[2] : 0) + childReviewsGroups.reduce((sum, g) => sum + (g.bottomline.star_distribution != null ? g.bottomline.star_distribution[2] : 0), 0),
      "1": (parentReviews.bottomline.star_distribution != null ? parentReviews.bottomline.star_distribution[1] : 0) + childReviewsGroups.reduce((sum, g) => sum + (g.bottomline.star_distribution != null ? g.bottomline.star_distribution[1] : 0), 0)
    };
    parentReviews.bottomline.average_score
      = (star_distribution[5] * 5
      + star_distribution[4] * 4
      + star_distribution[3] * 3
      + star_distribution[2] * 3
      + star_distribution[1] * 1) / total_review;

    parentReviews.bottomline.total_organic_reviews += childReviewsGroups.reduce((sum, g) => sum + g.bottomline.total_organic_reviews, 0);
    parentReviews.bottomline.star_distribution = star_distribution;
    parentReviews.bottomline.total_review = total_review;

    // merge pagination
    parentReviews.pagination.total += childReviewsGroups.reduce((sum, g) => sum + g.pagination.total, 0);
  }
  return parentReviews;
}

module.exports = {
  createReview,
  getProductReviews,
  getGroupedProductReviews,
  getAllSiteReviews,
  getAllProductsBottomLine,
  getAllReviews
};
